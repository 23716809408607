import React from "react";
import { Route, Routes } from "react-router";
import { useKeycloak } from "./_functions/auth/keycloak";
import Questinary from "./questinary";
import { NewsletterComponent } from "./newletter";
import { AuthorizeItem } from "./_functions/auth/authorize";
import { NewsletterLeave } from "./newletter/leave";
import { AppointmentComponent } from "./newletter/appointment";

export const App = (): JSX.Element => {
    const authCtx = useKeycloak()

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Questinary />
                }
            />
            <Route
                path="/newsletter"
                element={
                    <AuthorizeItem key={`router:newsletter`} ctx={authCtx.keycloak} roleName={`anketa-newsletter-admin`}>
                        <NewsletterComponent />
                    </AuthorizeItem>
                }
            />
            <Route
                path="/public/newsletter/:newsletter/unsubscribe/:userid"
                element={
                    <NewsletterLeave />
                }
            />
            <Route
                path="/public/newsletter/:newsletter/appointment/:userid"
                element={
                    <AppointmentComponent />
                }
            />
        </Routes>
    )
}