import React, { useContext } from 'react';
import { EuiText, EuiMarkdownFormat, EuiSpacer, EuiIconTip } from '@elastic/eui';
import { AnketaContext } from '../context_provider';
import { Section, SectionType } from 'anketa-core';
import { SectionCardProps } from '../../_types';

export const SectionCard = ({ section }: SectionCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext);

  if (!section.visible) {
    return <div></div>;
  }
  let title;
  if (section.sectionType === SectionType.Volume) {
    title = <h2>{ctx.i18nContext.render(section.title)}</h2>;
  } else if (section.sectionType === SectionType.Chapter) {
    title = <h3>{ctx.i18nContext.render(section.title)}</h3>;
  } else {
    let parent = section.parent;
    let lvl = 1;
    while (parent !== null) {
      if (parent instanceof Section && parent.sectionType === SectionType.SubChapter) {
        lvl++;
        if (lvl > 2) {
          break;
        }
      }
      parent = parent.parent;
    }
    if (lvl > 2) {
      title = <b>{ctx.i18nContext.render(section.title)}</b>;
    } else if (lvl === 1) {
      title = <h6>{ctx.i18nContext.render(section.title)}</h6>;
    } else {
      title = <h5>{ctx.i18nContext.render(section.title)}</h5>;
    }
  }

  return (
    <div
      style={{
        boxShadow: '0 4px 4px -2px rgba(0, 0, 0, 0.2)',
        padding: '2px',
      }}
    >
      <EuiSpacer />
      <EuiText>
        {section.help ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <EuiIconTip
              aria-label={section.path}
              size="m"
              type="questionInCircle"
              color="primary"
              content={ctx.i18nContext.render(section.help)}
            />
          </div>
        ) : (
          <div>
            <EuiIconTip aria-label={section.path} size="m" type="" color="primary" content="" />
          </div>
        )}
        <a href={'#tree-' + section.path} id={section.path}>
          {title}
        </a>
        <EuiMarkdownFormat>{ctx.i18nContext.render(section.description)}</EuiMarkdownFormat>
      </EuiText>
      {ctx.childrenForItem(section)}
      <EuiSpacer />
    </div>
  );
};
