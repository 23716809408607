import { OpenQuestion, Section, Translation } from 'anketa-core';

export const generateRetentions = () => {
  const gRetentions = new Section('retentions', [
    new Translation('en', 'Retentions'),
    new Translation('de', 'Retentions'),
  ]);

  const retentionsElastiFlow = new OpenQuestion('retentions_elasti_flow', [
    new Translation('en', 'Elasti Flow'),
    new Translation('de', 'Elasti Flow'),
  ]);
  retentionsElastiFlow.help = [
    new Translation('en', 'Enter the retention period for Elasti Flow data (e.g., 30 days).'),
    new Translation(
      'de',
      'Geben Sie den Aufbewahrungszeitraum für Elasti Flow-Daten ein (z.B., 30 Tage).'
    ),
  ];

  gRetentions.addChild(retentionsElastiFlow);

  const retentionsWazuh = new OpenQuestion('retentions_wazuh', [
    new Translation('en', 'Wazuh'),
    new Translation('de', 'Wazuh'),
  ]);
  retentionsWazuh.help = [
    new Translation('en', 'Enter the retention period for Wazuh data (e.g., 90 days).'),
    new Translation(
      'de',
      'Geben Sie den Aufbewahrungszeitraum für Wazuh-Daten ein (z.B., 90 Tage).'
    ),
  ];

  gRetentions.addChild(retentionsWazuh);

  const retentionsCodex = new OpenQuestion('retentions_codex', [
    new Translation('en', 'Codex'),
    new Translation('de', 'Codex'),
  ]);
  retentionsCodex.help = [
    new Translation('en', 'Enter the retention period for Codex data (e.g., 180 days).'),
    new Translation(
      'de',
      'Geben Sie den Aufbewahrungszeitraum für Codex-Daten ein (z.B., 180 Tage).'
    ),
  ];

  gRetentions.addChild(retentionsCodex);

  const retentionsFlows = new OpenQuestion('retentions_flows', [
    new Translation('en', 'Flows'),
    new Translation('de', 'Flows'),
  ]);
  retentionsFlows.help = [
    new Translation('en', 'Enter the retention period for Flow data (e.g., 60 days).'),
    new Translation(
      'de',
      'Geben Sie den Aufbewahrungszeitraum für Flow-Daten ein (z.B., 60 Tage).'
    ),
  ];

  gRetentions.addChild(retentionsFlows);

  return gRetentions;
};
