import {
  ComplexQuestion,
  DateQuestion,
  Group,
  OpenQuestion,
  OptionsQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';
import { MedoOptionMap } from '../helpers/medoOptionsMap';

export const generateTechEnv = (parentId: string) => {
  const gEnvReq = new Group(`${parentId}_tech_req`, [
    new Translation('en', 'Site Requirements'),
    new Translation('de', 'Anforderung an den Standort'),
  ]);

  gEnvReq.help = [
    new Translation('en', 'Does the site meet all the requirements for the appliance?'),
    new Translation('de', 'Erfüllt der Standort alle Anforderungen für die Appliance?'),
  ];

  const rLocation = new Row(`${parentId}_location`, [
    new Translation('en', 'Location where the appliance will be installed.'),
    new Translation('de', 'Standort wo die Appliance installiert wird.'),
  ]);

  rLocation.help = [new Translation('en', 'Location'), new Translation('de', 'Standort')];

  const address = new OpenQuestion(`${parentId}_address`, [
    new Translation('en', 'Address'),
    new Translation('de', 'Addresse'),
  ]);
  rLocation.addChild(address);

  const customerContactRow = new Row('customer_contact_0', [
    new Translation('en', 'Contact Person'),
    new Translation('de', 'Ansprechpartner'),
  ]);
  const generateExternal = () => {
    const oldTrigger: ComplexQuestion = customerContactRow.children.get(
      `${parentId}_customer_${customerContactRow.children.size - 1}`
    ) as ComplexQuestion;
    oldTrigger.removeChangeListener(generateExternal);

    const item = new ComplexQuestion(
      `${parentId}_customer_${customerContactRow.children.size}`,
      [
        new Translation('en', `Contact Person ${customerContactRow.children.size}`),
        new Translation('de', `Ansprechpartner ${customerContactRow.children.size}`),
      ],
      'User'
    );
    item.addChangeListener(generateExternal);
    customerContactRow.addChild(item);
  };

  const customer = new ComplexQuestion(
    `${parentId}_customer_0`,
    [new Translation('en', 'Contact Person'), new Translation('de', 'Ansprechpartner')],
    'User'
  );
  customer.addChangeListener(generateExternal);
  customerContactRow.addChild(customer);

  rLocation.addChild(customerContactRow);

  const rEnvReq = new Row(`${parentId}_r_tech_req`, [
    new Translation('en', 'Site Requirements'),
    new Translation('de', 'Anforderung an den Standort'),
  ]);
  const twoRoom = new OptionsQuestion(
    `two_room_${parentId}`,
    [new Translation('en', 'Two Room Setup'), new Translation('de', 'Zwei Raum Setup')],
    'default.options.yes_no'
  );

  twoRoom.help = [
    new Translation('en', 'Specifies whether a two-room setup is required.'),
    new Translation('de', 'Gibt an, ob ein Zwei-Raum-Setup erforderlich ist.'),
  ];

  rEnvReq.addChild(twoRoom);

  const rInterfaces = new Row(`${parentId}_interfaces`, [
    new Translation('en', 'Switch Interfaces'),
    new Translation('de', 'Switch Interfaces'),
  ]);

  const tenGbe = new OptionsQuestion(
    `4_ten_gbe_port`,
    [
      new Translation('en', '4x 10 GB Interfaces avaible'),
      new Translation('de', '4x 10 GB Interfaces verfügbar'),
    ],
    'default.options.yes_no'
  );

  tenGbe.help = [
    new Translation('en', 'Indicates the number of free 10 GB interfaces available on the switch.'),
    new Translation('de', 'Gibt die Anzahl der freien 10-GB-Interfaces am Switch an.'),
  ];

  rInterfaces.addChild(tenGbe);

  const oneGbe = new OptionsQuestion(
    `2_gbe_port`,
    [
      new Translation('en', '2x 1 GB Interfaces avaible'),
      new Translation('de', '2x 1 GB Interfaces verfügbar'),
    ],
    'default.options.yes_no'
  );

  oneGbe.help = [
    new Translation('en', 'Indicates the number of free 1 GB interfaces available on the switch.'),
    new Translation('de', 'Gibt die Anzahl der freien 1-GB-Interfaces am Switch an.'),
  ];

  rInterfaces.addChild(oneGbe);

  const twoHu = new OptionsQuestion(
    `two_hu_${parentId}`,
    [
      new Translation('en', 'Two Rack Units'),
      new Translation('de', 'Zwei Höheneinheiten Frei im Rack'),
    ],
    'default.options.yes_no'
  );

  twoHu.help = [
    new Translation(
      'en',
      'Specifies if there are two free rack units available for the appliance.'
    ),
    new Translation('de', 'Gibt an, ob im Rack zwei Höheneinheiten für die Appliance frei sind.'),
  ];
  rEnvReq.addChild(twoHu);

  const pdu = new OptionsQuestion(
    `pdu_${parentId}`,
    [new Translation('en', 'PDU'), new Translation('de', 'PDU')],
    'default.options.yes_no'
  );

  pdu.help = [
    new Translation(
      'en',
      'Specifies if there are four free PDU units available for the appliance.'
    ),
    new Translation('de', 'Gibt an, ob vier PDU Slots für die Appliance frei sind.'),
  ];
  rEnvReq.addChild(pdu);

  const rOwnDevices = new Row(`hw_${parentId}`, [
    new Translation('en', 'Acces to the location'),
    new Translation('de', 'Zugang zu dem Standort'),
  ]);

  const appontment = new DateQuestion('end_support', [
    new Translation('en', 'Appointment'),
    new Translation('de', 'Termin'),
  ]);

  rOwnDevices.addChild(appontment);

  const announced = new OptionsQuestion(
    `announced_${parentId}`,
    [new Translation('en', 'Announced'), new Translation('de', 'Angekündigt')],
    'default.options.yes_no'
  );
  announced.help = [
    new Translation(
      'eng',
      'Have all persons concerned been informed of the appointment and have all accesses been approved?'
    ),
    new Translation(
      'de',
      'Sind alle betroffenen Person über den Termin Informiert und alle zugänge wurden genehmigt?'
    ),
  ];
  rOwnDevices.addChild(announced);

  const ownDevices = new OptionsQuestion(
    `devices_${parentId}`,
    [new Translation('en', 'Own Devices'), new Translation('de', 'Mitnahme eigener Geräte')],
    'default.options.yes_no'
  );
  ownDevices.help = [
    new Translation(
      'eng',
      'Do the devices of autonubil employees have to be registered in advance?'
    ),
    new Translation('de', 'Müssen die Geräte der autonubil Angestellten vorab angemeldet werden?'),
  ];
  rOwnDevices.addChild(ownDevices);

  gEnvReq.addChild(rLocation);

  gEnvReq.addChild(rEnvReq);

  gEnvReq.addChild(rInterfaces);
  gEnvReq.addChild(rOwnDevices);

  const gTechEnv = new Section(`${parentId}_tech_env`, [
    new Translation('en', 'Hardware'),
    new Translation('de', 'Hardware'),
  ]);
  const rTechEnv = new Row(`${parentId}_tech_env_0`, [
    new Translation('en', 'Hardware'),
    new Translation('de', 'Hardware'),
  ]);

  const generateTechRow = () => {
    const oldTrigger: OpenQuestion = gTechEnv.children
      .get(`${parentId}_tech_env_${gTechEnv.children.size - 1}`)
      ?.children.get(`${parentId}_components_${gTechEnv.children.size - 1}`) as OpenQuestion;
    oldTrigger.removeChangeListener(generateTechRow);

    const item = new Row(`${parentId}_tech_env_${gTechEnv.children.size}`, [
      new Translation('en', `${gTechEnv.children.size + 1}. Tech env`),
      new Translation('de', `${gTechEnv.children.size + 1}. Technische Landschaft`),
    ]);
    item.addChild(
      new OpenQuestion(`${parentId}_manufactory_name_${gTechEnv.children.size}`, [
        new Translation('en', 'Manufactory'),
        new Translation('de', 'Hersteller'),
      ])
    );

    const components = new OptionsQuestion(
      `${parentId}_components_${gTechEnv.children.size}`,
      [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
      MedoOptionMap.tech_components
    );
    components.addChangeListener(generateTechRow);
    item.addChild(components);

    gTechEnv.addChild(item);
  };

  gTechEnv.addChild(rTechEnv);

  const internalContactName = new OpenQuestion(`${parentId}_manufactory_name_0`, [
    new Translation('en', 'Manufactory'),
    new Translation('de', 'Hersteller'),
  ]);
  rTechEnv.addChild(internalContactName);

  const components = new OptionsQuestion(
    `${parentId}_components_0`,
    [new Translation('en', 'Components'), new Translation('de', 'Komponenten')],
    MedoOptionMap.tech_components
  );
  components.addChangeListener(generateTechRow);
  rTechEnv.addChild(components);

  return { gTechEnv, gEnvReq };
};
