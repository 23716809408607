import React, { type ChangeEvent, useContext, useState, useEffect, type FocusEvent } from 'react';
import {
  EuiSelect,
  type EuiSelectOption,
  EuiFlexItem,
  EuiFormRow,
  EuiFieldText,
  EuiFieldNumber,
  EuiComboBox,
  EuiRadioGroup,
  type EuiRadioGroupOption,
  type EuiComboBoxOptionOption,
  EuiRadio,
  EuiFormFieldset,
  EuiTextArea,
  EuiMarkdownEditor,
  getDefaultEuiMarkdownParsingPlugins,
  EuiText,
  EuiToolTip,
  EuiIconTip,
  EuiMarkdownFormat,
} from '@elastic/eui';
import { AnketaContext, EvaluationContext } from '../context_provider';
import {
  OpenQuestion,
  OptionsQuestion,
  DateQuestion,
  NumericQuestion,
  SuggestionsQuestion,
  Severity,
  type TextMessage,
  type Question,
  OptionsValidator,
  type NumericValidator,
  type EvaluatorValue,
  type TextValidator,
  ValidatorType,
  ComplexQuestion,
  MultiSelectOptionsQuestion,
} from 'anketa-core';
import { SuggestionsQuestionEditor } from './suggestions_question_editor';
import { DateQuestionEditor } from './date_question_editor';

import { remarkMermaidPlugin } from '../question_card/mermaid_plugin';
import { remarkSimplePlantumlPlugin } from '../question_card/plantuml_plugin';
import { ComplexQuestionEditor } from './complex_question_editor';

export interface QuestionCardProps {
  question: Question<EvaluatorValue>;
}

export const QuestionCard = ({ question }: QuestionCardProps): JSX.Element => {
  const ctx = useContext(AnketaContext);
  const evalCtx = useContext(EvaluationContext);
  const [invalid, setInvalid] = useState(false);
  const [displayMode, setDisplayMode] = useState('block');
  const [errors, setErrors] = useState(new Array<string>());

  function setValue(val: EvaluatorValue, revaluate?: boolean): void {
    question.setFactValue(val);
    const evaluationResult = question.validate(ctx);

    if (revaluate !== false) {
      if (!evaluationResult.diagnostics.hasProblems) {
        ctx.reevaluate();
      }
    }

    const errorList = new Array<string>();
    if (errors.length > 0 || (revaluate !== false && evaluationResult.diagnostics.hasError)) {
      for (const diag of evaluationResult.diagnostics) {
        if (diag.severity === Severity.Error) {
          errorList.push(ctx.i18nContext.render(diag.summary));
        }
      }
    }
    setErrors(errorList);
    setInvalid(evaluationResult.hasProblems);
    evalCtx.nextEvaluationId();
    console.debug('setValue', question.path, val, question.fact);
    if (!evaluationResult.diagnostics.hasError && revaluate) {
      ctx.saveToBrowser();
    }
  }

  function getOpenQuestionEditor(question: OpenQuestion): JSX.Element {
    if (question.validator && question.validator.type === ValidatorType.Markdown) {
      const parsingList = getDefaultEuiMarkdownParsingPlugins();
      parsingList.splice(2, 1);
      /*
      const emoticonPlugin = parsingList[2]
      if (emoticonPlugin) {
        (emoticonPlugin as any[])[1].emoticon = false
      }
      */
      // parsingList.push(remarkMermaidPlugin)
      parsingList.unshift([remarkSimplePlantumlPlugin, {}]);
      parsingList.unshift([remarkMermaidPlugin, { key: question.path.replaceAll('.', '_') }]);

      if (question.readonly === true) {
        return (
          <EuiMarkdownFormat
            aria-label={ctx.i18nContext.render(question.description)}
            parsingPluginList={parsingList}
          >
            {question.fact.toString()}
          </EuiMarkdownFormat>
        );
      } else {
        return (
          <EuiMarkdownEditor
            style={{ marginRight: '2em', width: '100%' }}
            aria-label={ctx.i18nContext.render(question.description)}
            parsingPluginList={parsingList}
            editorId={question.path}
            placeholder={ctx.i18nContext.render(question.placeholder)}
            initialViewMode="editing"
            value={question.fact.toString()}
            onChange={(value: string) => {
              setValue(value, false);
            }}
          />
        );
      }
    }

    if (question.validator && question.validator.type === ValidatorType.Text) {
      let lines = 1;
      const validator = question.getValidator<TextValidator>();
      if (validator?.multiLine) {
        lines = validator.maxLength / 80;
        if (lines > 6) {
          lines = 6;
        }

        return (
          <EuiTextArea
            name={question.path}
            placeholder={ctx.i18nContext.render(question.placeholder)}
            isInvalid={invalid}
            readOnly={question.readonly}
            value={question.fact.toString()}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setValue(e.target.value, false);
            }}
            onBlur={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setValue(e.target.value);
            }}
          />
        );
      }
    }
    return (
      <EuiFieldText
        name={question.path}
        placeholder={ctx.i18nContext.render(question.placeholder)}
        isInvalid={invalid}
        readOnly={question.readonly}
        value={question.fact.toString()}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value, false);
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          setValue(e.target.value, true);
        }}
      />
    );
  }

  function getNumericQuestionEditor(question: NumericQuestion): JSX.Element {
    const validator = question.getValidator<NumericValidator>();
    const min = validator?.min ? question.getValidator<NumericValidator>()?.min : undefined;
    const max = validator?.max ? question.getValidator<NumericValidator>()?.max : undefined;
    let val: number | undefined = question.fact.getFloatValue(ctx.i18nContext);
    if (isNaN(val)) {
      val = undefined;
    }

    return (
      <EuiFieldNumber
        name={question.path}
        placeholder={ctx.i18nContext.render(question.placeholder)}
        isInvalid={invalid}
        min={min}
        max={max}
        value={val}
        readOnly={question.readonly}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value, false);
        }}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value, true);
        }}
      />
    );
  }

  function getOptionsQuestionEditor(question: OptionsQuestion): JSX.Element {
    const count = question.getOptions(ctx).size;
    if (count < 4) {
      return getOptionsQuestionRadio(question);
    } else if (count < 8) {
      return getTable(question);
    }
    if (count > 250) {
      return getOptionsQuestionCombo(question);
    }
    return getOptionsQuestionSelect(question);
  }

  interface MultiQuestionSelectProps {
    question: MultiSelectOptionsQuestion;
  }

  interface MultiQuestionSelectState {
    selectedOptions: Array<{ value: string | undefined; label: string }>;
  }

  class MultiQuestionSelect extends React.Component<
    MultiQuestionSelectProps,
    MultiQuestionSelectState
  > {
    constructor(props: MultiQuestionSelectProps) {
      super(props);
      this.state = {
        selectedOptions: this.initialSelectedOptions(props.question),
      };
    }

    initialSelectedOptions(
      question: MultiSelectOptionsQuestion
    ): Array<{ value: string | undefined; label: string }> {
      const opts: Array<EuiComboBoxOptionOption<string>> = [];

      const factValues = question.fact.raw as string[] | undefined;

      question.getOptions(ctx).forEach((v: TextMessage, k: string): void => {
        const option: EuiComboBoxOptionOption<string> = {
          value: k,
          label: ctx.i18nContext.render(v),
        };
        opts.push(option);
      });

      return factValues
        ? factValues.map((value) => {
            const option = opts.find((opt) => opt.value === value);
            return option ? { value: option.value, label: option.label } : { value, label: value };
          })
        : [];
    }

    handleOnChange = (
      selectedOptions: Array<{ value: string | undefined; label: string }>
    ): void => {
      this.setState({ selectedOptions });
      const values = selectedOptions
        .map((option) => option.value)
        .filter((value): value is string => value !== undefined);
      this.props.question.setFactValues(values);
    };

    onCreateOption = (
      searchValue: string,
      flattenedOptions: EuiComboBoxOptionOption[] = []
    ): void => {
      const normalizedSearchValue = searchValue.trim().toLowerCase();

      if (!normalizedSearchValue) {
        return;
      }

      const newOption = {
        value: normalizedSearchValue,
        label: searchValue,
      };

      if (
        flattenedOptions.findIndex(
          (option) => option.label.trim().toLowerCase() === normalizedSearchValue
        ) === -1
      ) {
        const updateArray = [];
        this.state.selectedOptions.forEach((item) => {
          updateArray.push(item);
        });
        updateArray.push(newOption);
        this.setState((prevState) => ({
          selectedOptions: [...prevState.selectedOptions, newOption],
        }));
        this.handleOnChange(updateArray);
      }
    };

    render(): JSX.Element {
      const { question } = this.props;
      const { selectedOptions } = this.state;

      const opts: Array<EuiComboBoxOptionOption<string>> = [];

      if (!question.required || !question.fact.raw) {
        const chooseOption: EuiComboBoxOptionOption<string> = {
          value: OptionsValidator.options_choose,
          label: ctx.i18nContext.render(ctx.messages.get(OptionsValidator.options_choose)),
        };
        opts.push(chooseOption);
      }

      question.getOptions(ctx).forEach((v: TextMessage, k: string) => {
        const option: EuiComboBoxOptionOption<string> = {
          value: k,
          label: ctx.i18nContext.render(v),
        };
        opts.push(option);
      });

      return (
        <EuiComboBox
          key={question.path}
          placeholder={ctx.i18nContext.render(question.placeholder)}
          options={opts}
          selectedOptions={selectedOptions}
          onChange={(selectedOptions) => {
            this.handleOnChange(selectedOptions as Array<{ value: string; label: string }>);
          }}
          onCreateOption={this.onCreateOption}
          isClearable={true}
          fullWidth={false}
        />
      );
    }
  }

  function getMultiQuestionEditor(question: MultiSelectOptionsQuestion): JSX.Element {
    return <MultiQuestionSelect question={question} />;
  }

  function getTable(q: OptionsQuestion): JSX.Element {
    const header = new Array<JSX.Element>();
    const items = new Array<JSX.Element>();
    let lineNo = 1;
    const line = new Array<JSX.Element>();
    const opts = q.getOptions(ctx);
    const cellWidth = `${75 / opts.size}%`;
    const bgColor = '#ffffff';

    if (header.length === 0) {
      // header.push(<th key={`${q.path}.l${lineNo}`} style={{ backgroundColor: bgColor, borderBottom: 'solid 1px #F5F7FA' }}>&nbsp;</th>)
      opts.forEach((value: TextMessage, key: string) => {
        header.push(
          <th
            style={{
              borderBottom: 'solid 1px #F5F7FA',
              padding: '8px',
              width: cellWidth,
              textAlign: 'center',
              verticalAlign: 'top',
              backgroundColor: bgColor,
            }}
            key={q.path + '.k' + key}
          >
            &nbsp;{ctx.i18nContext.render(value)}&nbsp;
          </th>
        );
      });
    }

    // line.push((<td align="left" style={{ minWidth: '110px', marginRight: '60px', textAlign: 'left', verticalAlign: 'middle', backgroundColor: bgColor }} key={q.path}><span>{ctx.i18nContext.render(q.title)}</span></td>))
    opts.forEach((value: TextMessage, key: string) => {
      const selected = q.fact.toString() === key;
      line.push(
        <td
          onClick={() => {
            const button = document.getElementById(q.path + '.' + key);
            if (button instanceof HTMLElement) {
              button.click();
            }
          }}
          align="center"
          style={{
            width: cellWidth,
            textAlign: 'center',
            verticalAlign: 'middle',
            backgroundColor: bgColor,
          }}
          key={q.path + '.' + key}
        >
          <input
            type="radio"
            checked={selected}
            name={q.path}
            id={q.path + '.' + key}
            key={q.path + '.' + key}
            onChange={(e) => {
              setValue(key, false);
            }}
            onBlur={(e) => {
              setValue(key, true);
            }}
          ></input>
        </td>
      );
    });
    lineNo++;
    items.push(<tr key={`${q.path}.l${lineNo}`}>{line}</tr>);

    return (
      <EuiFormFieldset key={question.id}>
        <table style={{ margin: 6, padding: 6 }}>
          <thead>
            <tr style={{ backgroundColor: '#FAFBFD' }}>{header}</tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      </EuiFormFieldset>
    );
  }

  function getOptionsQuestionRadio(question: OptionsQuestion): JSX.Element {
    const opts = new Array<EuiRadioGroupOption>();
    question.getOptions(ctx).forEach((v: TextMessage, k: string) => {
      const option: EuiRadioGroupOption = {
        id: question.path + '.' + k,
        label: ctx.i18nContext.render(v),
      };
      opts.push(option);
    });
    const selectedId = question.fact.raw
      ? question.path + '.' + question.fact.raw?.toString()
      : undefined;
    if (opts.length < 4) {
      return (
        <EuiRadioGroup
          key={question.path}
          options={opts}
          idSelected={selectedId}
          onChange={(id) => {
            setValue(id.substring(question.path.length + 1));
          }}
        ></EuiRadioGroup>
      );
    } else {
      const items = new Array<JSX.Element>();
      opts.forEach((opt) => {
        const key = opt.id;
        items.push(
          <EuiRadio
            label={opt.label}
            checked={selectedId === question.path + '.' + key}
            name={question.path}
            id={question.path + '.' + key}
            key={question.path + '.' + key}
            onChange={(e) => {
              setValue(key);
            }}
          ></EuiRadio>
        );
      });
      return <span>{items}</span>;
    }
  }

  function getOptionsQuestionSelect(question: OptionsQuestion): JSX.Element {
    const opts: EuiSelectOption[] = [];

    if (
      !question.required ||
      question.fact.raw === undefined ||
      question.fact.raw.toString() === ''
    ) {
      const option: EuiSelectOption = {
        value: '',
        text: ctx.i18nContext.render(ctx.messages.get(OptionsValidator.options_choose)),
      };
      opts.push(option);
    }

    question.getOptions(ctx).forEach((v: TextMessage, k: string) => {
      const option: EuiSelectOption = {
        value: k,
        text: ctx.i18nContext.render(v),
      };
      opts.push(option);
    });

    return (
      <EuiSelect
        key={question.path}
        placeholder={ctx.i18nContext.render(question.placeholder)}
        options={opts}
        value={question.fact.raw?.toString()}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setValue(e.target.value);
        }}
      />
    );
  }

  function getOptionsQuestionCombo(question: OptionsQuestion): JSX.Element {
    const opts: Array<EuiComboBoxOptionOption<string>> = [];
    const sel: Array<EuiComboBoxOptionOption<string>> = [];
    question.getOptions(ctx).forEach((v: TextMessage, k: string) => {
      const option: EuiComboBoxOptionOption<string> = {
        key: k,
        label: ctx.i18nContext.render(v),
      };
      if (k === question.fact.raw?.toString()) {
        sel.push(option);
      }
      opts.push(option);
    });
    return (
      <EuiComboBox<string>
        key={question.path}
        placeholder={ctx.i18nContext.render(question.placeholder)}
        options={opts}
        selectedOptions={sel}
        onChange={(options: Array<EuiComboBoxOptionOption<string>>) => {
          if (options.length > 0 && options[0].key !== undefined) {
            setValue(options[0].key);
          } else {
            setValue('');
          }
        }}
      />
    );
  }

  function getEditor(question: Question<EvaluatorValue>): JSX.Element {
    if (question instanceof OpenQuestion) {
      return getOpenQuestionEditor(question);
    }
    if (question instanceof DateQuestion) {
      return (
        <DateQuestionEditor
          question={question}
          setValue={setValue}
          invalid={invalid}
        ></DateQuestionEditor>
      );
    }
    if (question instanceof SuggestionsQuestion) {
      return (
        <SuggestionsQuestionEditor
          question={question}
          setValue={setValue}
          invalid={invalid}
        ></SuggestionsQuestionEditor>
      );
    }
    if (question instanceof OptionsQuestion) {
      return getOptionsQuestionEditor(question);
    }
    if (question instanceof NumericQuestion) {
      return getNumericQuestionEditor(question);
    }
    if (question instanceof ComplexQuestion) {
      return (
        <ComplexQuestionEditor
          question={question}
          setValue={setValue}
          invalid={invalid}
        ></ComplexQuestionEditor>
      );
    }
    if (question instanceof MultiSelectOptionsQuestion) {
      console.log('Multi Select Options Question', question);
      return getMultiQuestionEditor(question);
    }
    return <pre>Unsupported Question Type</pre>;
  }

  useEffect(() => {
    setDisplayMode(question.visible ? 'block' : 'none');
  }, [evalCtx.evaluationId, invalid, question.visible, question.id, displayMode]);

  let label = ctx.i18nContext.render(question.title);
  if (question.required) {
    if (invalid) {
      label = '○ ' + label;
    } else {
      label = '● ' + label;
    }
  } else {
    if (invalid) {
      label = '○' + label;
    }
  }

  return (
    <EuiFlexItem
      key={question.path}
      grow={false}
      style={{ display: displayMode, minWidth: '200px' }}
    >
      {question.help ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <EuiIconTip
            aria-label={question.path}
            size="m"
            type="questionInCircle"
            color="primary"
            content={ctx.i18nContext.render(question.help)}
          />
        </div>
      ) : (
        <div>
          <EuiIconTip aria-label={question.path} size="m" type="" color="primary" content="" />
        </div>
      )}
      <EuiFormRow
        label={label}
        hasEmptyLabelSpace={false}
        helpText={ctx.i18nContext.render(question.description)}
        isInvalid={invalid}
        fullWidth={true}
        error={errors}
      >
        {getEditor(question)}
      </EuiFormRow>
      {ctx.childrenForItem(question)}
    </EuiFlexItem>
  );
};
