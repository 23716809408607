import React, { useContext, useEffect, useState } from 'react'
import { EuiPanel, EuiTitle } from '@elastic/eui'
import { AnketaContext, EvaluationContext } from '../context_provider'

export const CatalogCard = (props: any): JSX.Element => {
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)
  const [version, setVersion] = useState<number>(0)
  const [item, setItem] = useState<JSX.Element>(<></>)

  useEffect(() => {
    console.log("Ctx changed...", evalCtx.evaluationId)
    setVersion(evalCtx.evaluationId)
    setItem(<EuiPanel
      style={{
        border: "none",
        boxShadow: "none",
        background: "none"
      }}
    >
      <EuiTitle>
        <h3>{ctx.i18nContext.render(ctx.catalog.title)}</h3>
      </EuiTitle>
      {ctx.childrenForItem(ctx.catalog)}
    </EuiPanel>
    )
  }, [ctx, evalCtx])

  return (
    item
  )
}
