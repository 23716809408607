import React from 'react'
import ReactDOM from 'react-dom'
import './style/index.css'
import * as Sentry from '@sentry/react'
import '@elastic/eui/dist/eui_theme_amsterdam_light.css'

// Make sure all locales are loaded
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/pt'
import 'moment/locale/es'
import 'moment/locale/pl'
import 'moment/locale/ru'
import 'moment/locale/nl'
import 'moment/locale/en-gb'
import 'moment/locale/de-ch'
import 'moment/locale/de-at'
import { KeycloakAuth } from './_functions/auth'

import { BrowserRouter as Router } from "react-router-dom";
import { App } from './app'
import { ToastProvider } from './_functions/toast'
import { NewletterProvider } from './_functions'

Sentry.init({
  dsn: 'https://e28f31e97728315c4ba755a2823878e9@sentry.genesis.exanio.cloud/34',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.replayCanvasIntegration({
      enableManualSnapshot: true,
    }),
  ],
});

ReactDOM.render(
  <Router>
    <KeycloakAuth>
      <ToastProvider>
        <NewletterProvider>
          <App />
        </NewletterProvider>
      </ToastProvider>
    </KeycloakAuth >
  </Router>
  , document.getElementById('root'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
