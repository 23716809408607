import './style/App.css'
import React, { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  useEuiI18n,
  EuiPageContentBody,
  EuiPageContent,
  EuiSpacer,
} from '@elastic/eui'

import { AnketaContext, AnketaContextProvider, CatalogCard } from './components'
import { CatalogTree } from './components/catalog_tree'
import { AuditCatalog, Translation, type Catalog } from 'anketa-core'
import { ActionConsumer } from './components/action_consumer'
import { useKeycloak } from './_functions/auth/keycloak'
import { NoCatalogSelected } from './components/pages/no_catalog'
import { AuthorizeItem } from './_functions/auth/authorize'

import { ArcticWolfCatalog } from './catalogs/arcticWolf'
import { newcustomerCatalog } from './catalogs/new_sutomer'
import { OpsanioCatalog } from './catalogs/opsanio'
import { EvaluationContext } from './components/context_provider'
import { WarningPage } from './components/pages/warning'
import moment from 'moment'
import { useApi } from './_functions/api/api'
import { VmwareCatalog } from './catalogs/vmware'
import { CatalogButtonsProps, CatalogTypes, questionarieParams } from './_types'
import { Avatar } from './components/avatar'


export const catalogButtons: CatalogButtonsProps[] = [
  {
    id: 'newcustomer',
    label: [new Translation('en', "New customer appointment"), new Translation('de', "Neu Kunden Termin")],
    iconType: 'https://medocino.net/wp-content/uploads/2020/09/Logo-neu-1024x271.png',
    description: [new Translation('en', ""), new Translation('de', "")],
  },
  {
    id: 'arcticwolf',
    label: [new Translation('en', "Arctic Wolf"), new Translation('de', "Arctic Wolf")],
    iconType: 'https://medocino.net/wp-content/uploads/2022/02/medocino_Partnerlogos-Artic-Wolf.png',
    description: [new Translation('en', ""), new Translation('de', "")],
  },
  {
    id: 'opsanio',
    label: [new Translation('en', "opsanio On-Boarding"), new Translation('de', "opsanio On-Boarding")],
    iconType: "https://avatars.githubusercontent.com/u/12993243?s=280&v=4",
    description: [new Translation('en', ""), new Translation('de', "")],
  },
  {
    id: 'bsi',
    label: [new Translation('en', "BSI"), new Translation('de', "BSI")],
    iconType: "https://www.bsi.bund.de/_config/richsnippets/logo.jpg?__blob=normal&v=2",
    description: [new Translation('en', ""), new Translation('de', "")],
  },
  {
    id: 'vmware',
    label: [new Translation('en', "VMware"), new Translation('de', "VMware")],
    iconType: "https://upload.wikimedia.org/wikipedia/commons/2/2e/VMware-vSphere-7.jpg",
    description: [new Translation('en', ""), new Translation('de', "")],
  }
]

export default function Questinary(): JSX.Element {
  const authCtx = useKeycloak()
  const ctx = useContext(AnketaContext)
  const evalCtx = useContext(EvaluationContext)

  const { Get, Post } = useApi();

  const [language, _setLanguage] = useState<string>('de-de')
  const [catalog, _setCatalog] = useState<Catalog>(AuditCatalog.instance.clone())
  const [tableItems, setTableItems] = useState<questionarieParams[]>([])
  const [toggleIdToSelectedLanguageMap, setToggleIdToSelectedLanguageMap] = useState({
    'de-de': true
  } as any)
  const [catalogType, setCatalogType] = useState<CatalogTypes>()
  const [sideNav, setSideNav] = useState<boolean>(catalogType ? true : false)
  const [createRoleAccess, setCreateRoleAccess] = useState<string>("")
  const [isCustomerView, setIsCustomerView] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [lastChangeMsg, setLastChangeMsg] = useState<string>("")

  const updateSentryContext = (): void => {
    Sentry.setContext('anketa', {
      locale: language,
      catalog: catalog ? catalog.id : 'N/A'
    })
  }
  const languageButtons = [
    {
      id: 'en-us',
      label: useEuiI18n('euiContext.english', 'English')
    },
    {
      id: 'de-de',
      label: useEuiI18n('euiContext.german', 'Deutsch')
    }
  ]
  const setLanguage = (lang: string): void => {
    _setLanguage(lang)
    setToggleIdToSelectedLanguageMap({ [lang]: true })
    updateSentryContext()
  }

  const catalogChangeListener = () => {
    console.log("reevaluate...", evalCtx.evaluationId)
    ctx.reevaluate()
    evalCtx.nextEvaluationId()
  }

  const setCatalog = (catalogId: string): void => {
    Get({ path: `questionaries/catalog/${catalogId.split(":")[0]}/${catalogId.split(":")[1]}`, token: authCtx.keycloak?.token })
      .then((result) => {
        const resultData: questionarieParams = result.data
        let catalogInstance: any
        if (authCtx.keycloak) {
          switch (resultData.id.split(":")[0]) {
            case 'newcustomer':
              catalogInstance = new newcustomerCatalog(resultData.id.split(":")[1], catalogChangeListener, authCtx.keycloak);
              break
            case 'arcticwolf':
              catalogInstance = new ArcticWolfCatalog(resultData.id.split(":")[1], catalogChangeListener, authCtx.keycloak);
              break
            case 'opsanio':
              catalogInstance = new OpsanioCatalog(resultData.id.split(":")[1], catalogChangeListener, authCtx.keycloak);
              break
            case 'vmware':
              catalogInstance = new VmwareCatalog(resultData.id.split(":")[1], catalogChangeListener, authCtx.keycloak);
              break
          }
        }
        if (catalogInstance) {
          setLastChangeMsg(`${resultData.updated_by}@${moment(resultData.last_updated).format('YYYY-MM-DD HH:mm:ss')}`)
          catalogInstance.factsFromObject(resultData.content);
          _setCatalog(catalogInstance);
          setSideNav(true)
          updateSentryContext()
        }

        console.log(result);
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const setCatlogeTypeFunc = (type: CatalogTypes) => {
    if (type === "audit") {
      fetchCatalogs()
    } else {
      if (type !== catalogType) {
        setTableItems([])
        setCatalogType(type)
      }
    }
  }
  const createNewCatalog = (catalogId: CatalogTypes, name: string) => {
    let catalogInstance: any
    if (authCtx.keycloak) {
      switch (catalogId) {
        case 'newcustomer':
          setCatalogType('newcustomer')
          catalogInstance = new newcustomerCatalog(name, catalogChangeListener, authCtx.keycloak);
          break
        case 'arcticwolf':
          setCatalogType('arcticwolf')
          catalogInstance = new ArcticWolfCatalog(name, catalogChangeListener, authCtx.keycloak);
          break
        case 'opsanio':
          setCatalogType('opsanio')
          catalogInstance = new OpsanioCatalog(name, catalogChangeListener, authCtx.keycloak);
          break
        case 'vmware':
          setCatalogType('vmware')
          catalogInstance = new VmwareCatalog(name, catalogChangeListener, authCtx.keycloak);
          break
      }
    }
    if (catalogInstance) {
      catalogInstance.clone();
      if (catalogInstance) {
        const params: questionarieParams = {
          id: `${catalogId}:${catalogInstance.id}`,
          name: ctx.i18nContext.render(catalogInstance.title),
          content: ctx.catalog.factsToObject(catalogInstance),
          catalog: catalogId,
          updated_by: authCtx.profile?.username || "",
          last_updated: moment().toISOString()
        }
        Post({ path: `questionaries/save/${catalogId}/${catalogInstance.id}`, value: params, token: authCtx.keycloak?.token })
          .then(() => {
            fetchCatalogs()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    }
  }

  const addItem = (newItem: questionarieParams) => {
    setTableItems(prevItems => {
      const itemExists = prevItems.some(item => item.id === newItem.id);
      if (itemExists) {
        return prevItems;
      } else {
        return [...prevItems, newItem];
      }
    });
  };
  const setCatalogItems = (catalogType: string | undefined, data: any) => {
    switch (catalogType) {
      case 'newcustomer':
        data.forEach((child: questionarieParams) => {
          const item = child.content
          const id = child.name.toLowerCase().replace(/ /g, '_')
          if (authCtx.keycloak) {
            const catalogInstance = newcustomerCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
            catalogInstance.clone();
            catalogInstance.factsFromObject(item)
          }
          addItem(child)
        })
        break
      case 'arcticwolf':
        data.forEach((child: questionarieParams) => {
          const item = child.content
          const id = child.name.toLowerCase().replace(/ /g, '_')
          if (authCtx.keycloak) {
            const catalogInstance = ArcticWolfCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
            catalogInstance.clone();
            catalogInstance.factsFromObject(item)
          }
          addItem(child)
        })
        break
      case 'opsanio':
        data.forEach((child: questionarieParams) => {
          const item = child.content
          const id = child.name.toLowerCase().replace(/ /g, '_')
          if (authCtx.keycloak) {
            const catalogInstance = OpsanioCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
            catalogInstance.clone();
            catalogInstance.factsFromObject(item)
          }
          addItem(child)
        })
        break
      case 'vmware':
        data.forEach((child: questionarieParams) => {
          const item = child.content
          const id = child.name.toLowerCase().replace(/ /g, '_')
          if (authCtx.keycloak) {
            const catalogInstance = VmwareCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
            catalogInstance.clone();
            catalogInstance.factsFromObject(item)
          }
          addItem(child)
        })
        break
    }
  }
  const getRoleForCreate = (role?: string): string => {

    if (role) {
      return role
    } else {
      switch (catalogType) {
        case 'newcustomer':
          return "anketa-newcustomer-admin"
        case 'arcticwolf':
          return "anketa-arcticwolf-admin"
        case 'opsanio':
          return "anketa-opsanio-admin"
        case 'vmware':
          return "anketa-vmware-admin"
        default:
          return "anketa-admin"
      }
    }

  }

  const fetchCatalogs = async () => {
    try {
      const promises = catalogButtons.map((catalog) =>
        Get({ path: `questionaries/catalog/${catalog.id}`, token: authCtx.keycloak?.token })
      );

      const results = await Promise.all(promises);

      const allCatalogData = results.flatMap(result => result.data);

      allCatalogData.forEach((child: questionarieParams) => {
        if (child.id) {
          const item = child.content
          const id = child.name.toLowerCase().replace(/ /g, '_')
          const catalogType = child.id.split(":")[0]
          let catalogInstance: any
          switch (catalogType) {
            case 'newcustomer':
              if (authCtx.keycloak) {
                catalogInstance = newcustomerCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
              }
              break
            case 'arcticwolf':
              if (authCtx.keycloak) {
                catalogInstance = ArcticWolfCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
              }
              break
            case 'opsanio':
              if (authCtx.keycloak) {
                catalogInstance = OpsanioCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
              }
              break
            case 'vmware':
              if (authCtx.keycloak) {
                catalogInstance = VmwareCatalog.createInstance(id, catalogChangeListener, authCtx.keycloak);
              }
              break
          }
          if (catalogInstance) {
            catalogInstance.clone();
            catalogInstance.factsFromObject(item)
            addItem(child)
          }
        }
      })

    } catch (err) {
      console.error(err);
    }
  };

  const getCatalogIdRole = (roles: string[]) => {
    const idMap = roles.map(role => role.replace(/^anketa-/, '').replace(/-admin$/, ''));
    const idSet = new Set<string>();

    const ids = idMap.filter(role => {
      const parts = role.split("-");
      return parts.length >= 2;
    }).map(role => {
      const parts = role.split("-");
      const catalog = parts[0];
      const id = parts.slice(1).join("-");
      const key = `${catalog}-${id}`;

      if (!idSet.has(key)) {
        idSet.add(key);
        return { catalog, id };
      }
      return null;
    }).filter(item => item !== null) as { catalog: string; id: string }[];

    return ids
  };


  useEffect(() => {
    if (authCtx.keycloak) {
      const resourceAccess = authCtx.keycloak?.resourceAccess
      const clientAccess = resourceAccess && resourceAccess["anketa-demo"] && resourceAccess["anketa-demo"].roles
      const anketaRoles = clientAccess && clientAccess?.filter(item => item.startsWith('anketa-') && item !== "anketa-opsanio");

      const single = getCatalogIdRole(anketaRoles ? anketaRoles : [])
      single.forEach((item) => {
        Get({ path: `questionaries/catalog/${item.catalog}/${item.id}`, token: authCtx.keycloak?.token })
          .then((result) => {
            if (result.data.length !== 0) {
              setCatalogItems(item.catalog, result.data)
            }
          }).catch((err) => {
            console.error(err)
          })
      })
      if (clientAccess?.includes("anketa-opsanio") || clientAccess?.includes("anketa-newcustomer") || clientAccess?.includes("anketa-arcticwolf") || clientAccess?.includes("anketa-admin")) {
        if (authCtx.keycloak?.token !== undefined && catalogType) {
          setCreateRoleAccess(getRoleForCreate)
          Get({ path: `questionaries/catalog/${catalogType}`, token: authCtx.keycloak?.token })
            .then((result) => {
              if (result.data.length !== 0) {
                setCatalogItems(catalogType, result.data)
              }
            }).catch((err) => {
              console.error(err)
            })
        } else if (authCtx.keycloak?.token && !catalogType) {
          fetchCatalogs()
        } else {
          console.log("Waiting for Keycloak Token....")
        }
      } else if (anketaRoles !== undefined && anketaRoles.length !== 0) {
        anketaRoles?.forEach((role) => {
          const catalog = role.split("-")[1]
          const customer = role.split("-")[2]
          setIsCustomerView(true)
          setCreateRoleAccess(getRoleForCreate(anketaRoles && anketaRoles[0]))
          Get({ path: `questionaries/catalog/${catalog}/${customer}`, token: authCtx.keycloak?.token })
            .then((result) => {
              if (result.data.length !== 0) {
                setCatalog(`${catalog}:${customer}`)
                setCatlogeTypeFunc(catalog as CatalogTypes)
                setCatalogItems(catalog, result.data)
              }
            }).catch((err) => {
              console.error(err)
            })
        })
      } else {
        setError(true)
      }
    }
  }, [catalogType, authCtx, ctx])

  if (error) {
    return < WarningPage error="403" />
  } else {
    return (
      <AnketaContextProvider locale={language} catalog={catalog}>
        <EuiPage>
          <EuiPageBody>
            <AuthorizeItem ctx={authCtx.keycloak} roleName={createRoleAccess}>
              <ActionConsumer />
            </AuthorizeItem>
            <EuiPageHeader
              pageTitle="Welcome back to Anketa"
              rightSideItems={[
                <EuiFlexGroup gutterSize="s" alignItems="center">
                  <EuiFlexItem>
                    <EuiButtonGroup options={languageButtons} legend="Language" type="multi"
                      idToSelectedMap={toggleIdToSelectedLanguageMap} onChange={(id: string) => {
                        setLanguage(id)
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <Avatar />
                  </EuiFlexItem>
                </EuiFlexGroup>
              ]}
            />
            <EuiPageContent style={{ overflowY: "auto", height: "90vh", background: "none", border: "none", boxShadow: "none" }}>
              <EuiSpacer />
              <EuiPageContentBody>
                {isCustomerView === false ?
                  <NoCatalogSelected
                    setCatalogType={setCatlogeTypeFunc}
                    buttons={catalogButtons}
                    items={tableItems}
                    setCatalog={setCatalog}
                    children={catalog.id !== "audit" && <CatalogCard />}
                    createNewCatalog={createNewCatalog}
                    lastChangeMsg={lastChangeMsg}
                  />
                  :
                  catalog.id !== "audit" && <CatalogCard />
                }
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </AnketaContextProvider >
    )
  }
}

