import {
  ComplexFactory,
  OpenQuestion,
  OptionsQuestion,
  Row,
  Section,
  Translation,
} from 'anketa-core';
import { MedoUser } from '../helpers/medocino_user';

export const generateHeader = (name: string) => {
  ComplexFactory.registerClass({
    name: 'MedoUser',
    Constructor: MedoUser as new (init?: any) => MedoUser,
    form: MedoUser.getForm,
  });

  const gHeader = new Section('basic', [
    new Translation('en', 'General data'),
    new Translation('de', 'Allgemeine Daten'),
  ]);
  const headerRow = new Row('header', [
    new Translation('en', 'Customer Profile'),
    new Translation('de', 'Kundensteckbrief'),
  ]);
  const companyName = new OpenQuestion('name', [
    new Translation('en', 'Company Name'),
    new Translation('de', 'Firmenname'),
  ]);
  companyName.setFactValue(name);
  headerRow.addChild(companyName);

  gHeader.addChild(headerRow);

  const customerHeaderRow = new Row('chr', [
    new Translation('en', 'Customer Profile'),
    new Translation('de', 'Kundensteckbrief'),
  ]);

  const kritis = new OptionsQuestion(
    'kritis',
    [new Translation('en', 'KRITIS'), new Translation('de', 'KRITIS')],
    'default.options.yes_no'
  );
  kritis.help = [
    new Translation('en', 'Indicates if the project is part of critical infrastructure (KRITIS).'),
    new Translation(
      'de',
      'Gibt an, ob das Projekt Teil der kritischen Infrastruktur (KRITIS) ist.'
    ),
  ];
  customerHeaderRow.addChild(kritis);

  gHeader.addChild(customerHeaderRow);

  return gHeader;
};
