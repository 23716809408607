import React, { useContext, useState, useEffect, type FocusEvent } from 'react'
import { EuiComboBox, type EuiComboBoxOptionOption } from '@elastic/eui'
import { AnketaContext } from '../context_provider'
import { type TextMessage } from 'anketa-core'
import { SuggestionsQuestionEditorProps } from '../../_types'



function sortUnique(arr: Array<EuiComboBoxOptionOption<string>>, locales?: string): Array<EuiComboBoxOptionOption<string>> {
  if (arr.length === 0) return arr
  const map = {} as any

  arr = arr.sort(function (a: EuiComboBoxOptionOption<string>, b: EuiComboBoxOptionOption<string>) { return a.label.localeCompare(b.label, locales) })
  const ret = [arr[0]]
  for (let i = 1; i < arr.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(map, arr[i].label)) {
      ret.push(arr[i])
      map[arr[i].label] = true
    }
  }
  return ret
}

export const SuggestionsQuestionEditor = ({ question, setValue, invalid }: SuggestionsQuestionEditorProps): JSX.Element => {
  const ctx = useContext(AnketaContext)

  const [options, setOptions] = useState(new Array<EuiComboBoxOptionOption<string>>())
  const [selected, setSelected] = useState(new Array<EuiComboBoxOptionOption<string>>())

  useEffect(() => {
    const opts: Array<EuiComboBoxOptionOption<string>> = []
    const sel: Array<EuiComboBoxOptionOption<string>> = []
    question.getOptions(ctx).forEach((v: TextMessage, k: string) => {
      if (!ctx.i18nContext.matchesLanguage(v)) {
        return
      }
      const lbl = ctx.i18nContext.render(v)
      const option: EuiComboBoxOptionOption<string> = {
        key: k,
        label: lbl
      }
      if (lbl === question.fact.raw?.toString()) {
        sel.push(option)
      }
      opts.push(option)
    })
    if ((sel.length === 0) && (question.fact.raw?.toString()) && (question.fact.raw?.toString() !== '')) {
      const option: EuiComboBoxOptionOption<string> = {
        key: question.fact.raw?.toString() ?? '?',
        label: question.fact.raw?.toString() ?? '?'
      }
      sel.push(option)
      opts.push(option)
    }
    setSelected(sel)
    setOptions(sortUnique(opts, ctx.i18nContext.locale.baseName))
  }, [ctx, question, question.fact.raw, ctx.locale])

  const onCreateOption = (searchValue: string, flattenedOptions: EuiComboBoxOptionOption[] = []) => {
    if (!searchValue) {
      return;
    }

    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue
    };

    if (
      flattenedOptions.findIndex(
        (option) =>
          typeof option.label === 'string' && option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      setOptions([...options, newOption]);
    }

    setSelected((prevSelected) => [...prevSelected, newOption]);
  };


  return (
    <EuiComboBox
      key={question.path}
      placeholder={ctx.i18nContext.render(question.placeholder)}
      options={options}
      isInvalid={invalid}
      selectedOptions={selected}
      singleSelection={{ asPlainText: true }}
      onCreateOption={onCreateOption}
      onChange={(options: Array<EuiComboBoxOptionOption<string>>) => {
        if ((options.length > 0) && (options[0].label !== undefined)) {
          console.log('Suggest On Change', options[0])
          setValue(options[0].label)
        } else {
          console.log('Suggest On Change', 'EMPTY')
          setValue('')
        }
      }}
      onBlur={(e: FocusEvent<HTMLDivElement>) => { setValue(e.currentTarget.innerText, true) }}
    />
  )
}
