import {
  EuiButton,
  EuiButtonEmpty,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiModalFooter,
  EuiPanel,
  EuiSwitch,
} from '@elastic/eui';
import React, { useState } from 'react';
import { ColumnConfig } from '../../_types';
export const ColumnSort = <T,>({
  closeModal,
  initConfig,
  changeConfig,
  key,
}: {
  closeModal: (state: 'cancle' | 'finish') => void;
  initConfig: ColumnConfig<T>;
  changeConfig: (config: ColumnConfig<T>) => void;
  key: string;
}): JSX.Element => {
  const [columnConfig, _setColumnConfig] = useState<ColumnConfig<T>>(initConfig);

  const onDragEndFilter = (result: any) => {
    console.log('Drag End');

    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const items = Object.entries(columnConfig);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    const updatedConfig: ColumnConfig<T> = {};
    items.forEach(([key, value], index) => {
      updatedConfig[key] = {
        ...value,
        priority: index,
      };
    });

    _setColumnConfig(updatedConfig);
  };

  const toggleColumnVisibility = (key: string) => {
    _setColumnConfig((prevConfig) => ({
      ...prevConfig,
      [key]: {
        ...prevConfig[key],
        visible: !prevConfig[key].visible,
      },
    }));
  };

  return (
    <>
      <EuiDragDropContext onDragEnd={onDragEndFilter}>
        <EuiDroppable droppableId={`DROPPABLE:AREA:${key}`} spacing="m" withPanel>
          {Object.entries(columnConfig)
            .sort(([, a], [, b]) => a.priority - b.priority)
            .map(([key, value]) => (
              <EuiDraggable
                spacing="m"
                key={key}
                index={value.priority}
                draggableId={key}
                customDragHandle={true}
              >
                {(provided, state) => (
                  <EuiPanel>
                    <EuiFlexGroup alignItems="center">
                      <EuiFlexItem grow={false} {...provided.dragHandleProps}>
                        <EuiIcon type="grab" aria-label={`${key}grab`} />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiSwitch
                          id={key}
                          name={key}
                          label={`Show ${key}`}
                          checked={value.visible}
                          onChange={() => toggleColumnVisibility(key)}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>{state.isDragging && ' ✨'}</EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                )}
              </EuiDraggable>
            ))}
        </EuiDroppable>
      </EuiDragDropContext>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={() => closeModal('cancle')}>Abbrechen</EuiButtonEmpty>
        <EuiButton onClick={() => changeConfig(columnConfig)}>Fertig</EuiButton>
      </EuiModalFooter>
    </>
  );
};
