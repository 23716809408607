import { CatalogObject, Translation } from 'anketa-core';
import { generateHeader } from './1Customer';
import { generateAutonubil } from './2Exanio';
import { generateNetwork } from './network';
import { generateRetentions } from './retentions';
import { generateActions } from './actions';
import { KeycloakInstance } from 'keycloak-js';
import { generateChecklist } from './check_list';
import { AuthorizeComponent } from '../../_functions';

export class OpsanioCatalog extends CatalogObject {
  private static _instance: OpsanioCatalog;

  public static get instance(): OpsanioCatalog {
    if (!OpsanioCatalog._instance) {
      throw new Error(
        'OpsanioCatalog instance has not been created. Use createInstance(name) method first.'
      );
    }
    return OpsanioCatalog._instance;
  }

  public static createInstance(
    name: string,
    changeListener: () => void,
    authCtx: KeycloakInstance
  ): OpsanioCatalog {
    if (!OpsanioCatalog._instance) {
      OpsanioCatalog._instance = new OpsanioCatalog(name, changeListener, authCtx);
    }
    return OpsanioCatalog._instance;
  }

  constructor(name: string, changeListener: () => void, authCtx: KeycloakInstance) {
    const id: string = name.toLowerCase().replace(/ /g, '-');
    super(id, [new Translation('en', name), new Translation('de', name)]);

    const opsanioAdmin: boolean = AuthorizeComponent(authCtx, 'anketa-admin')

    // HEADER
    const header = generateHeader(name);
    this.addChild(header);

    // AUTONUBIL
    const autonubil = generateAutonubil(name, !opsanioAdmin);
    this.addChild(autonubil);

    // CUSTOMER SIDE
    const networks = generateNetwork(name, 'c', true, changeListener);
    this.addChild(networks);

    // SCANNERS
    // this.addChild(geberateScanenrs())

    // RETENTIONS
    const retentions = generateRetentions();
    this.addChild(retentions);

    if (opsanioAdmin) {
      // CHECKLIST
      const checklist = generateChecklist();
      this.addChild(checklist);
    }

    // ACTIONS
    this.addChild(generateActions(`opsanio_${id}`, changeListener, authCtx));
  }
}
