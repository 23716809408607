import { ComplexType, Form, Item, OpenQuestion, OptionMap, OptionsQuestion, Row, SuggestionsQuestion, TextValidator, TextValidatorType, Translation } from "anketa-core"
import { MedoOptionMap } from "./medoOptionsMap"


export class MedoUser extends ComplexType {
    userid!: string

    constructor(init?: any) {
        super('MedoUser', init)
    }


    static getForm(): Item {
        const c = new Form('MedoUser', [new Translation('en', 'Customer Contact'), new Translation('de', 'Kunden Kontakt')])

        const rGender = new Row('gender', [new Translation('en', 'Gender'), new Translation('de', 'Gender')])
        const qSex = new OptionsQuestion('sex', [new Translation('en', 'Gender'), new Translation('de', 'Geschlecht')], OptionMap.sex)
        rGender.addChild(qSex)

        const qvName = new OpenQuestion('firstname', [new Translation('en', 'Firstname'), new Translation('de', 'Vorname')])
        qvName.validator = new TextValidator(TextValidatorType.Name)
        qvName.placeholder = [new Translation('en', 'first name'), new Translation('de', 'Vorname')]
        c.addChild(qvName)

        const qName = new OpenQuestion('surname', [new Translation('en', 'Name'), new Translation('de', 'Nachname')])
        qName.validator = new TextValidator(TextValidatorType.Name)
        qName.placeholder = [new Translation('en', 'last name'), new Translation('de', 'Nachname')]
        c.addChild(qName)

        c.addChild(rGender)

        c.addChild(new OpenQuestion('email', [new Translation('en', 'eMail Address'), new Translation('de', 'E-Mail Adressese')]))

        c.addChild(new OpenQuestion('phone', [new Translation('en', 'Phone number'), new Translation('de', 'Telefonnummer')]))


        return c
    }
}
