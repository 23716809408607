import {
  Group,
  NumericQuestion,
  OpenQuestion,
  Row,
  Section,
  TextValidator,
  TextValidatorType,
  Translation,
} from 'anketa-core';
import { generateTechEnv } from './tech_env';

export const generateNetwork = (
  title: string,
  parent: string,
  hadComponents: boolean,
  changeListener: () => void
) => {
  const gNetworkEnv = new Section(`${parent}_network_env`, [
    new Translation('en', `Network side by ${title}`),
    new Translation('de', `Netwerk bei der ${title}`),
  ]);

  const rHeader = new Row(`${parent}_network_header`, [
    new Translation('en', 'Number of Networks'),
    new Translation('de', 'Anzahl an Netzwerken'),
  ]);

  const qNumberOfNetworks = new NumericQuestion(`${parent}_nr_network_env`, [
    new Translation('en', 'Number of Networks'),
    new Translation('de', 'Anzahl an Netzwerken'),
  ]);
  qNumberOfNetworks.help = [
    new Translation('en', `How many networks does the ${title} have?`),
    new Translation('de', `Wieviele Netzwerke besitzt die ${title}?`),
  ];

  const generateNetworkRow = () => {
    if (qNumberOfNetworks.fact.raw) {
      const numberOfNetworks = parseInt(qNumberOfNetworks.fact.raw as string);
      for (let i = 0; i < numberOfNetworks; i++) {
        const newId = i;

        const item = new Group(`${parent}_ne_${newId}`, [
          new Translation('en', `${newId + 1}. Network Name`),
          new Translation('de', `${newId + 1}. Netzwerknamen`),
        ]);

        const networkName = new OpenQuestion(`nn_${newId}`, [
          new Translation('en', 'Network Name'),
          new Translation('de', 'Netzwerknamen'),
        ]);
        networkName.help = [
          new Translation('en', 'Enter the name of the network.'),
          new Translation('de', 'Geben Sie den Namen des Netzwerks ein.'),
        ];

        networkName.validator = new TextValidator(TextValidatorType.Name);
        item.addChild(networkName);

        // TECH ENV
        // if (hadComponents) {
        //   const techEnv = generateTechEnv(`${parent}_ne_${newId}`);
        //   item.addChild(techEnv.gEnvReq);
        // }

        const network = new OpenQuestion(`network_${newId}`, [
          new Translation('en', 'Network'),
          new Translation('de', 'Netzwerk'),
        ]);
        network.validator = new TextValidator(TextValidatorType.CIDR);
        network.help = [
          new Translation('en', 'Enter the network in CIDR notation (e.g., 192.168.0.0/24).'),
          new Translation(
            'de',
            'Geben Sie das Netzwerk in CIDR-Notation ein (z.B., 192.168.0.0/24).'
          ),
        ];

        item.addChild(network);

        const gateway = new OpenQuestion(`gateway_${newId}`, [
          new Translation('en', 'Gateway'),
          new Translation('de', 'Gateway'),
        ]);
        gateway.validator = new TextValidator(TextValidatorType.IP);
        gateway.help = [
          new Translation('en', 'Enter the gateway IP address.'),
          new Translation('de', 'Geben Sie die Gateway-IP-Adresse ein.'),
        ];

        item.addChild(gateway);

        gNetworkEnv.addChild(item);

        // TECH ENV
        if (hadComponents) {
          const techEnv = generateTechEnv(`${parent}_ne_${newId}`);
          item.addChild(techEnv.gTechEnv);
        }
      }
    }
  };

  const setupNetworks = () => {
    const item = new Group(`${parent}_gp_setup`, [
      new Translation('en', `CPE Customer transfer Network`),
      new Translation('de', `CPE Customer transfer Network`),
    ]);

    const networkName = new OpenQuestion(`nn_setup`, [
      new Translation('en', 'Network Name'),
      new Translation('de', 'Netzwerknamen'),
    ]);
    networkName.help = [
      new Translation('en', 'Enter the name of the network.'),
      new Translation('de', 'Geben Sie den Namen des Netzwerks ein.'),
    ];

    networkName.setFactValue('Customer');
    networkName.readonly = true;
    item.addChild(networkName);

    // TECH ENV
    if (hadComponents) {
      const techEnv = generateTechEnv(`${parent}_te_setup`);
      item.addChild(techEnv.gEnvReq);
    }

    const network = new OpenQuestion(`network_setup`, [
      new Translation('en', 'Network'),
      new Translation('de', 'Netzwerk'),
    ]);
    network.validator = new TextValidator(TextValidatorType.CIDR);
    network.help = [
      new Translation('en', 'Enter the network in CIDR notation (e.g., 192.168.0.0/24).'),
      new Translation('de', 'Geben Sie das Netzwerk in CIDR-Notation ein (z.B., 192.168.0.0/24).'),
    ];

    item.addChild(network);

    const gateway = new OpenQuestion(`gateway_setup`, [
      new Translation('en', 'Gateway'),
      new Translation('de', 'Gateway'),
    ]);
    gateway.validator = new TextValidator(TextValidatorType.IP);
    gateway.help = [
      new Translation('en', 'Enter the gateway IP address.'),
      new Translation('de', 'Geben Sie die Gateway-IP-Adresse ein.'),
    ];

    item.addChild(gateway);

    const sharedIp = new OpenQuestion(`shared_ip_setup`, [
      new Translation('en', 'Shared IP'),
      new Translation('de', 'Shared IP'),
    ]);
    sharedIp.validator = new TextValidator(TextValidatorType.IP);
    sharedIp.help = [
      new Translation('en', 'Enter the shared IP address.'),
      new Translation('de', 'Geben Sie die geteilte IP-Adresse ein.'),
    ];

    item.addChild(sharedIp);

    const dnsIp = new OpenQuestion(`dns_setup`, [
      new Translation('en', 'DNS Server'),
      new Translation('de', 'DNS Server'),
    ]);
    dnsIp.validator = new TextValidator(TextValidatorType.IP);
    dnsIp.help = [
      new Translation('en', 'Enter the DNS Server IP address.'),
      new Translation('de', 'Geben Sie die DNS Server IP-Adresse ein.'),
    ];

    item.addChild(dnsIp);

    const vlan = new OpenQuestion(`vlan_setup`, [
      new Translation('en', 'VLAN'),
      new Translation('de', 'VLAN'),
    ]);
    vlan.validator = new TextValidator(TextValidatorType.VLAN);
    vlan.help = [
      new Translation('en', 'Enter the VLAN ID (a number between 1 and 4094).'),
      new Translation('de', 'Geben Sie die VLAN-ID ein (eine Zahl zwischen 1 und 4094).'),
    ];

    item.addChild(vlan);

    // TECH ENV
    if (hadComponents) {
      const techEnv = generateTechEnv(`${parent}_ne_setup`);
      item.addChild(techEnv.gTechEnv);
    }

    return item;
  };

  rHeader.addChild(qNumberOfNetworks);
  qNumberOfNetworks.addChangeListener(generateNetworkRow);
  qNumberOfNetworks.addChangeListener(changeListener);
  gNetworkEnv.addChild(setupNetworks());
  gNetworkEnv.addChild(rHeader);

  return gNetworkEnv;
};
